import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({ urlVideo }) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-[55%] mx-auto absolute md:bottom-[20%] bottom-[30%] md:text-start text-center text-white ">
                    <div className="w-full md:text-start text-center md:pl-[15%] pl-0 z-[15]">
                        <h1 className="text-[38px] md:text-[3.5em] capitalize">{rpdata?.dbSlogan?.[0].slogan}</h1>
                        <p>{rpdata?.dbValues?.[0].description}</p>
                        <ButtonContent />
                    </div>
                </div>

                <img
                    src="https://firebasestorage.googleapis.com/v0/b/imagenes-backup.appspot.com/o/Hero%20Nails%2FChris%201.png?alt=media&token=74812b6c-9686-4695-82f6-91f2dc9ac155"
                    className="absolute md:bottom-0 -bottom-10 right-0 md:w-[30%] w-[40%] md:mb-0 mb-10  h-auto"
                    alt="Not Found"
                />
            </div>
        </div>
    );
}
export default HeroVideo;