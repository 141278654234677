import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { ButtonContent } from '../boton/ButtonContent'
// import { BiPhoneCall } from 'react-icons/bi'
// import { BsCalendar4Range, BsClockHistory } from 'react-icons/bs'





const HeaderTwo = ({ btnName }) => {

    const { rpdata } = useContext(GlobalDataContext)

    return (
        <header className='w-full flex justify-center backgroundheader3 z-30'>
            <div className='w-4/5'>
                <div className='flex w-full justify-between items-center'>
                    <div className='md:flex hidden'>
                        <ButtonContent btnStyle='four' btnLink={'contact'} />
                    </div>


                    <div className='w-full md:w-[35%] py-4'>
                        <img
                            src={rpdata?.dbPrincipal?.logo}
                            alt='logo'
                            loading='lazy'
                            className='md:w-[65%] w-[80%] mx-auto'
                        />
                    </div>


                    <div className='hidden md:flex w-[18%] justify-center items-center '>
                        <ul className="flex justify-center space-x-7">
                            {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                                return (
                                    <li key={index} className="textColor1">
                                        <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                            <i
                                                className={`text-white fab fa-${item.icon}`}
                                                aria-hidden="true"
                                            />
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>

                <div className='px-3 py-4'>
                    <div className='flex justify-between md:justify-center items-center border-b-gray-500 border-b-2 pb-2 mb-2'>
                       
                        <div className='block md:hidden'>
                            <ButtonContent btnStyle='four'/>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderTwo
